@import 'app/theme/mixins';

.banner {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  button {
    border: 3px solid transparent;
    min-width: 120px;
    @include desktop {
      padding: 7px !important;
    }
    @include phone {
      padding: 14px !important;
    }
  }

  button:hover,
  button:active,
  button:focus {
    border: 3px solid $hover-border;
    background-color: $white !important;
    color: $hover-color !important;
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    video {
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include phone {
        max-height: 100%;
      }
    }

    & ~ .content > * {
      z-index: 100;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $black;

    .descriptionWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      _:-ms-fullscreen,
      :root & {
        //IE flexbox fix
        min-height: 100px;
        width: 100%;

        > * {
          width: 100%;
          text-align: center;
        }
      }

      &:after {
        content: '';
        min-height: inherit;
        font-size: 0;
        display: block;
      }

      .title {
        font-family: $sans-pro;
        font-size: 3.375rem;
        font-weight: 900;
        line-height: 3.375rem;
        letter-spacing: -0.69px;

        &.dark {
          color: $dark-blue;
        }

        &.light {
          color: $white;
        }
      }

      .subtitle {
        font-family: $sans-pro;
        font-size: 0.9375rem;
        font-weight: 400;
        line-height: 1.188rem;
        letter-spacing: 0;
        &.dark {
          color: $black;
        }

        &.light {
          color: $white;
        }
      }

      .counter {
        font-size: 7.5rem;
        line-height: 1;
        font-weight: 900;

        @include tablet {
          font-size: 4.5rem;
        }

        @include phone {
          font-size: 4.25rem;
        }
      }

      blockquote {
        margin: 0;
        padding: 0;

        .subtitle {
          font-size: 0.75rem;
          line-height: 0.9375rem;
          font-weight: 600;
          letter-spacing: 0;
          color: $black;
        }
      }
    }

    .embeddedAsset {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 90%;
      }
    }

    .featuresList {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 60px;

      _:-ms-fullscreen,
      :root & {
        //IE11
        margin-bottom: 0;
      }

      @include phone {
        justify-content: space-around;
      }

      .feature {
        display: flex;
        flex-direction: column;
        align-items: center;

        _:-ms-fullscreen,
        :root & {
          //IE flexbox fix
          min-height: 100px;
          width: 100%;

          > * {
            width: 100%;
          }
        }

        &:after {
          content: '';
          min-height: inherit;
          font-size: 0;
          display: block;
        }

        span {
          text-align: center;
        }

        &.smallRound {
          margin-right: 30px;

          &:last-child {
            margin-right: 0;
          }

          @include phone {
            width: 40%;
            margin-right: 0;
            margin-bottom: 30px;

            &:last-child {
              margin: 0 auto 30px auto;
            }
          }

          img {
            width: 130px;
            height: 130px;

            @include tablet {
              width: 110px;
              height: 110px;
            }

            @include phone {
              width: 90px;
              height: 90px;
            }
          }

          span {
            text-align: center;
            margin-top: 15px;
            color: $dark-grey;
            font-size: 0.875rem;
            font-weight: 700;
          }
        }

        &.smallSquare {
          width: 31%;
          background-color: transparent;
          padding: 20px;
          border-radius: 20px;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }

          @include below-desktop {
            margin-right: 15px;
          }

          @include phone {
            width: 60%;
            margin: 0 0 20px 0;
          }

          @include small-phone {
            width: 90%;
          }

          .description {
            color: $black;
            text-align: center;
            line-height: 26px;
            margin-top: 10px;
            padding: 0 15px;

            @include phone {
              margin-top: 0;
              padding: 0 5px;
            }
          }

          img {
            width: 100%;
            margin-bottom: 15px;
          }
        }

        &.smallSquareImage {
          width: 30%;
          padding: 0 20px 10px 20px;
          border-radius: 20px;
          margin-right: 20px;

          &:nth-child(3),
          &:nth-child(5) {
            margin-right: 0;
          }

          &:nth-child(4),
          &:nth-child(5) {
            margin-top: 20px;
          }

          @include below-desktop {
            margin-right: 15px;
          }

          @include phone {
            width: 60%;
            margin: 0 0 20px 0;
          }

          @include small-phone {
            width: 90%;
          }

          span {
            color: $black;
            font-size: 1.5rem;
            line-height: 1.5rem;
            font-weight: 900;
            padding: 0 10px;
          }

          .description {
            color: $black;
            font-size: 1rem;
            line-height: 1rem;
            margin-top: 10px;
            padding: 0 5px;
            text-align: center;

            & > div {
              line-height: 1.575rem;
            }

            h1 {
              font-size: 1.5rem;
              line-height: 1.5rem;
            }

            @include phone {
              margin-top: 0;
              padding: 0 5px;
            }
          }

          img {
            margin: 20px 0 10px 0;
            width: 110px;
            height: 110px;
            object-fit: none;
          }
        }

        &.bigSquare {
          color: $black;
          width: 30%;
          background: $white-smoke;
          justify-content: flex-start;
          border-radius: 38px;
          padding: 50px;

          &:first-child {
            margin-right: 10px;
          }

          &:last-child {
            margin-left: 10px;
          }

          @include below-desktop {
            width: 45%;
            padding: 30px;
          }

          @include phone {
            width: 80%;
            padding: 40px 15px;

            &:first-child,
            &:last-child {
              margin: 25px 0 0 0;
            }
          }

          @include small-phone {
            width: 95%;
          }

          span {
            color: $dark-grey;
            font-size: 1.75rem;
            color: $dark-blue;
            line-height: 44px;
            font-weight: 700;
            padding: 0 10px;
            margin: 20px 0 10px 0;
          }

          .description {
            color: $black;
            text-align: left;
            line-height: 26px;
            margin-top: 10px;
            padding: 0 15px;

            h5 {
              font-size: 1.5rem;
              line-height: 1.5rem;
              margin: 10px 0;
            }

            b {
              display: inline-block;
              margin-top: 30px;
            }

            ul {
              padding-left: 20px;
              margin: 10px 0 0 0;

              li {
                padding-left: 25px;
              }
            }

            @include phone {
              margin-top: 0;
              padding: 0 5px;
            }
          }

          img {
            width: 70%;
            max-width: 250px;

            @include phone {
              max-width: 130px;
            }
          }
        }

        &.inline {
          flex-direction: row;
          justify-content: space-around;
          color: $light-grey;

          @include phone {
            flex-direction: column;
            align-items: center;
          }

          .description {
            width: 60%;
            font-size: 1.125rem;

            @include below-desktop {
              font-size: 0.925rem;
            }

            @include phone {
              font-size: 1.125rem;
              text-align: center;
              width: 80%;
            }

            div {
              margin-bottom: 20px;

              @include below-desktop {
                margin-bottom: 10px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          img {
            width: 30%;
            max-width: 230px;

            @include phone {
              margin-bottom: 35px;
              min-width: 170px;
            }
          }
        }
      }
    }
  }

  &.delivery-1-2-3 {
    // water-home-delivery
    .content {
      max-width: 945px;
      margin: 0 auto;

      .title {
        margin-bottom: 0;

        @include below-desktop {
          font-size: 2.875rem;
        }

        @include phone {
          font-size: 2.375rem;
          margin: 10px 5%;
          text-align: center;
        }

        &.dark {
          color: $primary-blue;
        }
      }

      .subtitle {
        font-size: 1.5rem;
        font-weight: 900;
        line-height: 1.563rem;
        letter-spacing: -0.31px;
        margin-bottom: 30px;

        @include phone {
          margin-bottom: 10px;
        }
      }

      .embeddedAsset {
        img {
          max-width: 360px;

          @include small-phone {
            width: 60%;
          }
        }
      }

      .featuresList {
        .feature {
          position: relative;

          @media screen and (-webkit-min-device-pixel-ratio: 0) {
            //IE11 exclude
            &:nth-child(1),
            &:nth-child(2) {
              &:before,
              &:after {
                border-right: 2px solid $light-green-arrow;
                content: '';
                display: block;
                height: 10px;
                margin-top: -7px;
                position: absolute;
                transform: rotate(135deg);
                right: -7px;
                top: 50%;
                width: 0;

                @include phone {
                  display: none;
                }
              }

              &:after {
                margin-top: -1px;
                transform: rotate(45deg);
                border-right: 2px solid $light-green-arrow;
              }
            }
          }

          img {
            margin: 10px 0;
          }

          span {
            display: none;
          }

          .description {
            line-height: 1rem;
            margin: 0;

            h5 {
              font-size: 1.5rem;
              line-height: 1.5rem;
              margin: 10px 0;
            }

            div {
              line-height: 1.3rem;

              &:first-child {
                font-weight: 600;
                &.dark {
                  color: $primary-blue;
                }
              }
            }
          }
        }
      }

      a {
        order: 1;
        margin: 40px 0;

        @include phone {
          margin: 30px 0 0 0;
        }

        button {
          padding: 9px 26px;

          @include phone {
            padding: 16px 26px;
          }
        }
      }
    }
  }

  &.water-home-our-services-sparkletts-411 {
    // water-home-our-services-sparkletts-411
    margin: 90px 0;

    @include phone {
      margin: 50px 0;
    }

    .content {
      max-width: 945px;
      margin: 0 auto;

      .title {
        margin: 0;

        @include below-desktop {
          font-size: 2.875rem;
          line-height: 2.875rem;
        }

        @include phone {
          font-size: 2.375rem;
          line-height: 2.375rem;
          margin: 10px 5%;
          text-align: center;
        }

        &.dark {
          color: $primary-blue;
        }
      }

      .subtitle {
        margin: 20px 0 0 0;
        padding: 0 25%;
        text-align: center;

        @include phone {
          padding: 0 5%;
        }
      }

      a {
        order: 1;
        margin: 70px 0 0 0;

        @include phone {
          margin: 20px 0 0 0;
        }

        button {
          padding: 9px 30px;
        }
      }

      .featuresList {
        .feature {
          margin: 20px 0 0 0;

          &:last-child {
            margin-right: 0;
          }

          span {
            @include phone {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }

  &.water-main-banner {
    // water-home-our-services-main-banner
    background: $primary-blue;

    .content {
      flex-direction: row-reverse;

      @include phone {
        flex-direction: column;
      }

      .images {
        display: flex;
        flex-wrap: wrap;
        width: 50%;

        @include phone {
          flex-wrap: nowrap;
          width: 100%;
        }

        .embeddedAsset {
          margin: 0;

          img {
            width: 100%;
          }

          &:nth-child(1) {
            width: 100%;
            margin: 0;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 50%;
          }
        }
      }

      .descriptionWrapper {
        width: 50%;

        @include phone {
          width: 100%;
          margin-bottom: 50px;
        }

        .title {
          margin-bottom: 0.67em;

          padding: 0 15%;
          text-align: center;

          @include below-desktop {
            font-size: 3.375rem;
            line-height: 3.375rem;
          }

          @include phone {
            font-size: 2.375rem;
            line-height: 2.375rem;
          }
        }

        .subtitle {
          margin-bottom: 50px;

          @include tablet {
            margin-bottom: 20px;
          }

          padding: 0 10%;
          text-align: center;
        }

        a {
          @include phone {
            margin-bottom: 20px;
          }

          button {
            padding: 9px 30px;

            @include tablet {
              margin-bottom: 25px;
            }

            @include phone {
              padding: 16px 30px;
            }
          }
        }
      }
    }
  }

  &.water-svg-banner {
    // water-where-to-buy-quality
    background-color: $white-grey;
    padding-top: 80px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      width: 50%;

      @include phone {
        width: 90%;
      }

      .title {
        margin-top: 0;
        text-align: center;
        &.dark {
          color: $primary-blue;
        }
      }

      .subtitle {
        text-align: center;
        color: $black;
      }
    }
  }

  &.water-secindary-banner {
    // water-residential-our-story-we-believe
    .content {
      flex-direction: row-reverse;

      @include phone {
        flex-direction: column-reverse;
      }

      .images {
        display: flex;
        width: 50%;

        @include phone {
          width: 100%;
        }

        .embeddedAsset {
          margin: 0;

          img {
            width: 100%;
          }
        }
      }

      .descriptionWrapper {
        width: 50%;

        @include phone {
          width: 100%;
          margin: 75px 0;
        }

        @include small-phone {
          margin: 50px 0;
        }

        .title {
          margin: 0;
          padding: 0 20%;
          text-align: center;

          @include below-desktop {
            font-size: 2.875rem;
            line-height: 2.875rem;
          }

          @include phone {
            font-size: 2.375rem;
            line-height: 2.375rem;
            padding: 10px 5%;
          }

          &.dark {
            color: $primary-blue;
          }
        }

        .subtitle {
          margin: 20px 30px;
          padding: 0 5%;
          text-align: center;

          @include below-desktop {
            margin: 5px 15px;
          }

          @include phone {
            padding: 0 5%;
            margin: 5px 0 20px 0;
          }
        }
      }
    }
  }

  &.water-feature-products {
    // water-residential-home-products
    margin-bottom: 20px;

    .content {
      .descriptionWrapper {
        .title {
          margin: 0 20% 20px 20%;
          text-align: center;

          @include below-desktop {
            font-size: 2.875rem;
          }

          @include phone {
            font-size: 2.375rem;
            margin: 10px 5%;
            text-align: center;
          }

          &.dark {
            color: $primary-blue;
          }
        }

        .subtitle {
          margin-bottom: 30px;
          text-align: center;

          @include phone {
            margin-bottom: 10px;
          }
        }

        .featuresList {
          .feature {
            justify-content: space-between;
            margin: 0;

            @include phone {
              margin-bottom: 70px;
              width: 80%;
            }

            & > span {
              display: none;
            }

            button span {
              display: block;
            }

            &.smallSquare {
              img {
                width: auto;
                height: auto;
                max-width: 100%;
                max-height: 375px;
              }
            }

            img {
              width: auto;
              height: 250px;

              @include below-desktop {
                width: auto;
                height: 200px;
              }

              @include phone {
                width: auto;
                height: 270px;
              }
            }

            .description {
              display: flex;
              flex-direction: column;
              @include phone {
                padding-top: 5px;
              }

              h1 {
                color: $black;
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin: 5px 0 10px 0;
                order: 1;
                @include phone {
                  min-height: 50px;
                }
              }

              & > div {
                color: $black;
                font-size: 0.625rem;
                text-transform: uppercase;
                font-weight: 900;
              }

              & > div:last-child {
                order: 1;
              }

              a {
                button {
                  @include phone {
                    padding: 16px 40px;
                  }

                  span {
                    font-size: 0.625rem;
                    color: $white;
                    line-height: 0.8125rem;
                    font-weight: 700;
                    margin: 0;
                  }
                }
                button:hover,
                button:active,
                button:focus {
                  span {
                    color: $hover-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.water-about-main-banner {
    width: 100%;

    .content {
      .descriptionWrapper {
        display: flex;
        flex-direction: row-reverse;
        background-color: $primary-blue;
        width: 100%;

        @include phone {
          flex-direction: column;
        }

        .water-about-main-banner-image {
          width: 50%;
          height: 422px;

          @include phone {
            height: 188px;
            width: 100%;
          }
        }

        .water-about-main-banner-text {
          margin: 0 auto;

          @include phone {
            width: 100%;
            padding: 4% 10% 4% 10%;
          }

          .descriptionWrapper {
            flex-direction: column;
            text-align: center;

            .title {
              color: $white;
              word-break: keep-all;
            }

            .subtitle {
              padding-bottom: 20px;
              color: $black;
              padding-top: 4%;

              @include phone {
                width: 81%;
              }
            }
          }
        }
      }
    }
  }

  &.water-about-our-mission {
    padding: 137px 30% 137px 30%;
    text-align: center;

    @include phone {
      padding-left: 10%;
      padding-right: 10%;
      padding: 58px 0px 58px 0px;
    }

    .subtitle {
      color: $white;
      margin-bottom: 1.1875rem;
      width: 63%;

      @include tablet {
        width: 100%;
      }
    }

    .title {
      color: $white;
      height: 4.75rem;
    }
  }

  &.water-about-our-portfolio {
    background-color: $white-grey;
    text-align: center;

    .content {
      max-width: 945px;
      margin: 0 auto;
      padding: 80px 0;

      @include phone {
        padding-left: 10%;
        padding-right: 10%;
      }

      @include tablet {
        padding-left: 7%;
        padding-right: 7%;
      }
    }

    .descriptionWrapper {
      align-items: flex-start;
      width: 100%;

      > ul {
        padding-left: 0;
        width: 100%;
      }

      .content {
        padding: 0;
      }

      .title {
        margin-top: 0;
        &.dark {
          color: $primary-blue;
        }
      }

      .subtitle {
        color: $black;
        width: 50%;
        text-align: left;

        a {
          display: block;
        }

        @include phone {
          width: 78%;
        }
      }

      .descriptionWrapper {
        margin-top: 35px;
        flex-direction: row;

        @include phone {
          flex-direction: column;
        }

        .listItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 25px;
          text-align: left;
          color: $black;
          div {
            :nth-child(1) {
              display: block;
            }
          }
        }

        .water-about-portfolio-right {
          margin-left: 20px;
          @include phone {
            margin-left: 0;
            margin-top: 0;
          }

          .content {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
          }

          .images {
            position: relative;
            width: 60%;
            left: 9%;
            padding-top: 4%;

            @include phone {
              left: 17%;
            }

            @include tablet {
              left: 18%;
            }
          }

          .embeddedAsset {
            @include phone {
              justify-content: left;
            }
          }
        }
      }
    }
  }

  &.water-about-retail {
    padding: 70px 10% 70px 10%;
    text-align: center;
    background: $white;

    @include phone {
      padding-left: 10%;
      padding-right: 10%;
    }

    .content {
      display: flex;
      flex-direction: column-reverse;

      .images {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 25px;
        max-width: 722px;

        .embeddedAsset {
          img {
            width: 100%;
          }
        }

        > div {
          padding: 0 4px;
        }
      }
    }
    .descriptionWrapper {
      .title {
        &.dark {
          color: $primary-blue;
        }
        @include phone {
          font-size: 2.375rem;
        }
      }

      .subtitle {
        width: 65%;

        @include phone {
          width: 72%;
        }
      }
    }
  }

  &.water-about-our-bottom-banner {
    width: 100%;

    .content {
      .descriptionWrapper {
        display: table;

        @include phone {
          display: flex;
          flex-direction: column;
        }

        .water-about-our-bottom-banner-we-believe {
          display: table-cell;
          background-color: $primary-blue;

          @include phone {
            display: flex;
          }

          .descriptionWrapper {
            padding: 150px 10% 150px 10%;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            a button {
              min-width: 123px;
            }

            .title {
              color: $white;
            }

            .subtitle {
              padding-bottom: 20px;
              color: $black;
              width: 61%;

              @include phone {
                width: 78%;
              }

              @include tablet {
                width: 96%;
              }
            }
          }
        }

        .water-about-our-bottom-banner-get-work {
          @extend .water-about-our-bottom-banner-we-believe;
          background-color: $lime;
        }
      }
    }
  }
}
