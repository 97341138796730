@import 'vars';

@mixin clear {
  padding: 0;
  margin: 0;
}

@mixin clear-list {
  @include clear;

  list-style: none;
}

@mixin flex-center($dir: row) {
  display: flex;
  flex-direction: $dir;
  justify-content: center;
  align-items: center;
}

@mixin flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex($dir: row) {
  display: flex;
  flex-direction: $dir;
  align-items: center;
}

@mixin small-phone {
  @media (max-width: $screen-xs) {
    @content;
  }
}

@mixin sub-phone {
  @media (max-width: $screen-ss) {
    @content;
  }
}

@mixin phone {
  @media (max-width: $screen-sm - 1px) {
    @content;
  }
}

@mixin v-phone {
  @media (max-width: $screen-sm - 1px) and (orientation: portrait) {
    @content;
  }
}

@mixin h-phone {
  @media (max-width: $screen-sm - 1px) and (orientation: landscape) {
    @content;
  }
}

@mixin v-tablet-phone {
  @media (max-width: $screen-sm) {
    @content;
  }
}

@mixin h-tablet {
  @media (min-width: $screen-sm + 1px) and (max-width: $screen-md) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $screen-sm) and (max-width: $screen-md) {
    @content;
  }
}

@mixin v-tablet {
  @media (min-width: $screen-sm) and (max-width: $screen-md) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-below {
  @media (max-width: $screen-md) {
    @content;
  }
}

@mixin below-desktop {
  @media (max-width: $screen-lg - 1px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $screen-lg) {
    @content;
  }
}

@mixin xlarge-desktop {
  @media (min-width: $screen-xl) {
    @content;
  }
}

@mixin tablet-or-above {
  @media (min-width: $screen-sm) {
    @content;
  }
}

// match theme/utils media
@mixin media-sm {
  @media (min-width: 320px) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: 1024px) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: 1440px) {
    @content;
  }
}
// end match

@mixin wide-spacing {
  letter-spacing: 1.3px;
}

// [data-tracking] {
//   border: 5px dashed purple !important;
// }
