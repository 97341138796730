@import 'app/theme/mixins';

.footer {
  font-family: $sans-pro;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  padding-top: 54px;
  padding-bottom: 42px;
  position: relative;

  @include below-desktop {
    background-size: contain;
    background-color: $dark-blue;
    padding: 15px 0;
  }

  .waveBorder {
    height: 50px;
    top: -49px;
    position: absolute;
    background-size: cover;
    width: 100%;

    @include phone {
      height: 25px;
      top: -24px;
    }
  }

  .footerBlocks {
    display: flex;

    @include phone {
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }

  .logo {
    display: block;
    margin-bottom: 45px;
    max-width: 100px;

    @include phone {
      margin-bottom: 30px;
    }

    img {
      width: 100%;
    }
  }
}
