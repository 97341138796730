@import 'variables';
@import 'mixins';
@import 'animations';

html,
body,
p {
  font-size: 16px;
  font-family: $sans-pro;
  line-height: 1.15;
}

[data-js-focus-visible] :focus:not([data-focus-visible-added]) {
  outline: none !important;
}

[data-focus-visible-added] {
  outline: $outline !important;
  outline-offset: 3px !important;
}

.buttonLink {
  color: inherit;
  text-decoration: inherit;
  cursor: pointer;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}

a {
  @extend .buttonLink;
  color: $primary-blue;
}
