@import 'app/theme/mixins';

.round {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;

  & > img.badge {
    height: 14px;
    width: 14px;
    position: absolute;
    left: -12px;

    &:nth-child(3) {
      top: -6px;
    }

    &:nth-child(2) {
      top: 6px;
    }

    &:nth-child(1) {
      top: 18px;
    }
  }
}

.selectedRound {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: $primary-blue;
  border: 1px solid rgba(0, 0, 0, 0.25);
  color: $white;
}

.fakeRound {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 1px solid inherit;
  background-color: inherit;
}

.recurringDelivery {
  @extend .round;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: $primary-dark-blue;
  color: $white;
}

.recurringDeliveryWithHover {
  &:hover {
    border: 1px solid $primary-dark-blue;
    color: $primary-dark-blue;
    background-color: $white;
    cursor: pointer;
  }
}

.holdDelivery {
  @extend .round;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: $white;
}

.holdDeliveryWithHover {
  &:hover {
    border: 1px solid $white;
    color: $white;
    background-color: $primary-dark-blue;
    cursor: pointer;
  }
}

.oneTimeDelivery {
  @extend .round;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: $primary-dark-blue;
  color: $white;
  &:hover {
    border: 1px solid $primary-dark-blue;
    color: $primary-dark-blue;
    background-color: $white;
    cursor: pointer;
  }
  /*
  MMorrison - current designs do not call for this specialzied style - leaving for propsperity
  border: 1px solid $primary-blue;
  background-color: $white;

  &::after {
    content: "";
    height: 7px;
    width: 7px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    background-color: $primary-blue;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 3px;
  }*/
}

.rescheduledDelivery {
  @extend .round;

  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: $lime;
}

.wrapper {
  &:hover {
    cursor: default;
  }
}
