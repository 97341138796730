@import 'app/theme/globals';

.promotionBanner {
  padding: 0.75rem;
  background: $ice;

  .descriptionWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    font-family: $nunito;
    font-size: 0.875rem;

    .embeddedAsset {
      height: 1.1em;

      img {
        height: 100%;
      }

      @include media-md {
        height: 1.125rem;
      }
    }

    .text {
      margin: 0.2rem 1rem 0.1rem 0.6rem;
      font-weight: 800;
      line-height: 1.4;
      color: $river;
      text-align: center;

      @include media-md {
        font-size: 1.125rem;
        font-weight: 900;
        line-height: 1.5;
      }
    }

    .btn {
      @include btn-river;

      a,
      button {
        font-size: 0.75rem;
      }

      @include media-md {
        @include btn--skinny;
      }
    }
  }
}
