@import 'app/theme/mixins.scss';

.formWrapper {
  text-align: center;

  .editForm {
    overflow-y: auto;
    text-align: left;
    display: flex;
    flex-direction: column;

    _:-ms-fullscreen,
    :root & {
      //IE11
      height: auto;
    }

    .formHeader {
      padding: 50px 30px 20px 30px;
      margin-right: 30px;

      > svg,
      > .icon {
        color: $lime;
        display: block;
        width: 30px;
        height: 30px;
      }

      h2 {
        color: $black;
        font-size: 2.375rem;
        letter-spacing: -1.19px;
        line-height: 1;
        margin-bottom: 14px;
        margin-top: 12px;
      }

      .subtext {
        _:-ms-fullscreen,
        :root & {
          //IE11
          margin-bottom: 20px;
        }
      }
    }

    .formBody {
      padding: 15px 30px 0 30px;
      width: 100%;

      _:-ms-fullscreen,
      :root & {
        //IE11
        width: auto;
      }

      .bottom {
        display: flex;
        margin: 20px;

        @include phone {
          margin: 20px 10px 20px 20px;
        }

        .options {
          min-width: 200px;

          @include phone {
            min-width: 160px;
          }
        }

        .button {
          margin-top: 15px;
          margin-left: 20px;

          @include phone {
            margin-left: 15px;
          }

          button {
            .informationImage {
              margin-right: 8px;
              img {
                height: 16px;
              }
            }
          }
        }
      }

      @include phone {
        margin-bottom: 0;

        > div {
          flex-direction: column;
        }
      }

      & > div:first-child {
        border-radius: 5px 5px 0 0;
      }

      & > div:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    .note {
      color: $middle-grey;
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      margin-left: 30px;
      text-align: left;
      padding-bottom: 160px;

      @include phone {
        margin: 0 30px 0 30px;
        padding-bottom: 170px;
      }
    }
  }

  .formBottom {
    width: 32rem;
    min-width: 470px;
    position: fixed;
    bottom: 0;
    right: 0;
    padding-bottom: 20px;
    background-color: $white;

    @include phone {
      padding-bottom: 0;
      left: 0;
      width: 100%;
      min-width: unset;
    }

    .additionalActions {
      margin: 25px 30px 5px 30px;
      position: relative;
      text-align: center;

      .editButton {
        margin-top: -2px;

        button: {
          display: inline;
        }
      }

      > * {
        margin: 0 10px 0 0;
      }

      .text {
        color: #000000;
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .inputFieldWithIcon {
        cursor: pointer;
        width: 140px;
        margin-left: 10px;
        height: 40px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div:before {
          border: none;
        }

        input {
          cursor: pointer;
          color: $blue;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          padding: 0 10px;
        }

        .calendarIcon {
          cursor: pointer;
          padding: 14px;
          height: 40px;
          width: 40px;
          border-radius: 0 5px 5px 0;
          background-color: $blue;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
          font-size: 10px;
          color: $white;
        }
      }
    }
    .formActions {
      margin-top: 25px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      _:-ms-fullscreen,
      :root & {
        //IE11
        margin-bottom: 20px;
      }

      @include phone {
        position: relative;
        width: 100%;
        min-width: unset;
        padding-bottom: 25px;
      }

      .cancelButton,
      .saveButton {
        width: 32%;
        min-width: 150px;
        text-align: center;
        margin: 0 5px;

        @include phone {
        }

        button {
          width: 100%;
        }
      }

      .cancelButton {
        span {
          color: $black !important;
        }

        button {
          background-color: $heavy-some !important;
        }
      }
    }
  }
}

.selectLabelContainer {
  color: $black;

  .informationImage {
    margin-right: 4px;
    img {
      height: 12px;
    }
  }
}
