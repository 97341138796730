@import 'app/theme/globals';

.topHeader {
  color: $white;
  background-color: #005dab;
  font-family: $sans-pro;
  font-size: 0.8125rem;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: 1rem;
  text-align: center;
  padding-top: 8px;

  @include phone {
    font-size: 0.5625rem;
    letter-spacing: -0.13px;
    line-height: 0.625rem;
  }

  .text {
    padding-left: 40px;
    padding-right: 40px;
    flex-grow: 1;

    @include phone {
      padding-left: 10px;
      padding-right: 10px;
    }

    > a {
      color: white;
      text-decoration: underline;
      font-size: 0.8125rem;
      font-family: $sans-pro;
      white-space: nowrap;

      @include phone {
        font-size: 0.5625rem;
      }

      &:focus {
        outline-color: $white !important;
      }
    }
    li {
      display: inline;
    }
  }

  .bottomLine {
    height: 5px;
    margin-top: 5px;
    background-color: #0078c7;

    .left {
      width: 50%;
      height: 5px;
      background-color: #18ac6a;
    }

    .right {
      background-color: #0078c7;
    }
  }
}

.costcoHeader {
  background-color: $river;
  color: $white;

  .text {
    li {
      a {
        color: $white;
        text-decoration: underline;
      }
    }
  }
}

.container {
  padding: 0 40px;
  background-color: $white;

  @include phone {
    padding: 0 10px;
  }

  .navigation {
    padding: 15px 0 18px 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: $smooth-transition;

    &.shrink {
      padding: 15px 0;
    }

    @include phone {
      padding: 12px 0;
    }

    .logo {
      width: 144px;

      @include phone {
        width: 104px;
      }

      img {
        width: 100%;
      }
    }

    .navbar {
      display: flex;
      align-items: center;

      button {
        border: 3px solid transparent;
        font-family: $nunito;
      }

      li {
        list-style: none;
      }

      .ulist {
        display: flex;
        align-items: center;
        margin: 0px;
        list-style-type: none;
        font-family: $nunito;

        .link {
          padding: 4px 9px;
          border-radius: 16px;
          color: $river;
          transition: $smooth-transition;
          font-weight: 600;
          margin-right: 3px;
          line-height: 1.4375rem;

          &:hover,
          &:active {
            color: $stream;
          }
        }

        & > button {
          color: $river;
        }
      }
      li.link > button:active {
        color: $stream;
      }
      .btn {
        button {
          border-radius: 0.625rem;
          font-size: 1rem;
          font-weight: 600;
          padding: 0.5625rem 0.9375rem;
          border-width: 1px;
          background-color: $river;
        }
        button:hover,
        button:active,
        button:focus {
          border: 1px solid $stream;
          color: $river;
          background-color: $white !important;
        }
      }

      .tooltips {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-md {
          flex-direction: row;
        }

        .costcoBtn {
          margin-right: 0;

          @include media-md {
            margin-right: 1.25rem;
          }
        }

        .loginWrapper {
          button:first-child {
            margin-right: 0;

            @include media-md {
              margin-right: 1rem;
            }
          }
        }

        button {
          left: 0 !important;
        }
      }

      img {
        cursor: pointer;
      }
    }
  }

  .selfServe {
    padding: 10px 0;

    .logo {
      width: 188px;

      @include phone {
        width: 144px;
      }
    }
  }

  .costcoWater {
    padding: 20px 0;
  }
}

.link {
  line-height: 2rem;
  border: 3px solid transparent;
}
