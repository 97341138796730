@import './water-regional.scss';

.footer {
  .container {
    .logo {
      @include tablet {
        flex-basis: 100%;
        margin-bottom: 20px;
      }

      @include phone {
        flex-basis: 100%;
        margin-bottom: 10px;
      }
    }

    .footerBlock {
      @include tablet {
        flex-basis: 100%;
      }

      @include phone {
        flex-basis: 100%;
      }
    }
  }
}
