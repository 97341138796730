@import 'app/theme/mixins';

.burger {
  .link {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 2;
  }

  .logout button {
    @extend .link;
  }

  .cart {
    @extend .link;
    display: block;
    margin: 16px 0;
  }

  :global {
    .bm-burger-button {
      position: absolute;
      width: 45px;
      height: 30px;
      right: 0;
      margin: auto;
      top: 0;
      bottom: 0;

      @include small-phone {
        width: 35px;
        height: 25px;
        right: 5px;
      }

      button {
        outline: none;
      }

      .bm-burger-bars {
        background: $primary-green;
        height: 5px;
        width: 100%;
        border-radius: 5px;
      }
    }

    .bm-menu-wrap {
      position: fixed;
      height: 100%;
      top: 0;

      .bm-cross-button {
        height: 24px;
        width: 24px;
        button {
          outline: none;
        }
      }

      .bm-cross {
        background: lighten($black, 70%);
      }

      .bm-menu {
        background: $white;
        padding: 2.5em 1.5em 0;

        .bm-item-list {
          height: auto !important;
        }
      }

      .bm-item {
        display: inline-block;
        outline: none;
        font-family: $sans-pro;

        a {
          font-size: 1rem;
          color: $primary-blue;
          text-decoration: none;
          text-transform: uppercase;
          transition: color 0.2s linear;
          font-weight: 700;

          @include phone {
            font-size: 0.75rem;
          }

          &:hover {
            color: $primary-green;
          }

          &.active {
            color: $primary-green;
          }
        }
      }
    }

    .bm-overlay {
      background: lighten($black, 70%);
      top: 0;
    }
  }
}
