@import 'app/theme/mixins.scss';

.formWrapper {
  text-align: center;

  @include phone {
    text-align: left;
  }

  .editForm {
    height: calc(100vh - 225px);
    overflow-y: auto;
    text-align: left;
    display: flex;
    flex-direction: column;
    margin: 0 0 0 30px;

    _:-ms-fullscreen,
    :root & {
      //IE11
      height: auto;
    }

    @include phone {
      height: calc(100vh - 245px);
    }

    .formHeader {
      padding: 50px 0 20px 0;
      margin-right: 30px;

      > svg,
      > .icon {
        color: $lime;
        display: block;
        width: 30px;
        height: 30px;
      }

      h2 {
        color: $black;
        font-size: 2.375rem;
        letter-spacing: -1.19px;
        line-height: 1;
        margin-bottom: 14px;
        margin-top: 12px;
      }
    }

    .formBody {
      padding: 15px 30px 15px 5px;
      width: 100%;

      _:-ms-fullscreen,
      :root & {
        //IE11
        width: auto;
      }

      @include phone {
        margin-bottom: 0;

        > div {
          flex-direction: column;
        }
      }

      & > div:first-child {
        border-radius: 5px 5px 0 0;
      }

      & > div:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  .formBottom {
    .additionalActions {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      position: relative;
      justify-content: center;
      align-items: center;

      @include phone {
        align-items: left;
      }

      .editButton {
        margin-top: -2px;
      }

      > * {
        width: 30%;
        margin: 0 10px 0 0;

        @include phone {
          width: 50%;
          margin: 0;
        }
      }

      h3 {
        color: $black;
        font-size: 15px;
        font-weight: 600;
        line-height: 19px;
        margin-top: 5px;
        margin-bottom: 5px;
      }

      .inputFieldWithIcon {
        cursor: pointer;
        width: 140px;
        margin-left: 10px;
        height: 40px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > div:before {
          border: none;
        }

        input {
          cursor: pointer;
          color: $primary-dark-blue;
          font-size: 12px;
          font-weight: 600;
          line-height: 15px;
          padding: 0 10px;
        }

        .calendarIcon {
          cursor: pointer;
          padding: 14px;
          height: 40px;
          width: 40px;
          border-radius: 0 5px 5px 0;
          background-color: $primary-dark-blue;
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
          font-size: 10px;
          color: $white;

          _:-ms-fullscreen,
          :root & {
            //IE11
            padding: 6px;
          }
        }
      }
    }
    .formActions {
      margin-top: 20px;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;

      _:-ms-fullscreen,
      :root & {
        //IE11
        margin-bottom: 20px;
      }

      @include phone {
        position: relative;
        width: 100%;
        min-width: unset;
      }

      .cancelButton,
      .saveButton {
        width: 32%;
        min-width: 160px;
        text-align: center;
        margin: 0 5px;

        @include phone {
          width: 40%;
          min-width: unset;
        }

        button {
          width: 100%;
        }
      }

      .cancelButton {
        span {
          color: $black !important;
        }

        button {
          background-color: $heavy-some !important;
        }
      }
    }
  }
}

.calendarContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding-top: 25px;

  > div > div {
    width: 100%;
    border: none;
  }
}

.calendarPopper {
  box-sizing: border-box;
  width: 332px;
  border: 3px solid $hover-border;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  left: 70px !important;
  margin-top: 0;
  z-index: 9999;

  @include phone {
    width: auto;
    padding: 0 20px;
    left: inherit !important;
  }

  .popperArrow {
    _:-ms-fullscreen,
    :root & {
      //IE11
      display: none;
    }

    &:after,
    &:before {
      content: '';
      z-index: -1;
      position: absolute;
      top: 0;
      right: 14%;
      box-sizing: border-box;
      transform-origin: 0 0;
      transform: rotate(135deg);
      border: 12px solid $primary-blue;
    }

    &:after {
      top: 4px;
      border-color: $white;
    }
  }

  &[x-placement*='top-end'] {
    .popperArrow {
      &:after,
      &:before {
        top: unset;
        bottom: -24px;
      }

      &:after {
        bottom: -20px;
        border: 12px solid $white;
      }
    }
  }
}

.calendarWrapper {
  width: 100%;
  border: 0;
}

.horizontalRule {
  width: 100%;
  height: 2px;
  background-color: $extra-light-grey;
  margin-top: 10px;
}

.legend {
  display: flex;
  flex-direction: row;
  padding: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  align-items: center;
}

.legendLabel {
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.625rem;
  font-weight: 900;
  flex-shrink: 0;
  margin-right: 20px;
}

.legendText {
  color: $dark-grey;
  margin-left: 10px;
  font-size: 0.75rem;
  font-weight: 600;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  word-break: keep-all;
}

.day {
  width: 30px;
  height: 30px;
  border: 1px solid $black;
  border-radius: 4px;
  background-color: $extra-light-grey;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recurringDay {
  position: relative;
  &::before {
    content: '';
    background-color: $primary-blue;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 1px;
    left: 1px;
  }
}

.suggestedDay {
  position: relative;
  &::after {
    content: '';
    background: linear-gradient(0deg, $green 5%, $lime 90%);
    position: absolute;
    bottom: -1px;
    right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-top-left-radius: 3px;
    border-bottom-right-radius: 3px;
    width: 9px;
    height: 9px;
  }
}

.suggestedDate {
  margin-right: 20px;
}

.suggestedDate,
.recurringDeliveryDate {
  display: flex;
  flex-direction: row;
}
