@import 'app/theme/mixins';

.order {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $white;
  width: 95%;
  height: 150px;
  padding: 15px 10px;
  margin: 0 20px 0 10px;
  position: relative;
  transition: $transition;
  border-bottom: 2px solid $heavy-some;

  &:last-child {
    margin-bottom: 98px;
    border-bottom: none;
  }

  &:hover {
    background: $white-grey;
  }

  @include tablet {
    padding: 25px 25px 30px 20px;
  }

  @include phone {
    flex-direction: column;
    align-items: center;
    padding: 25px 20px;
  }

  .orderType {
    margin: 0 10px;
  }

  .image {
    margin-right: 30px;
    width: 100px;
    height: 141px;

    @include phone {
      width: 152px;
      height: 130px;
      margin: 0;
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      position: relative;
      top: 50%;
      transform: translateY(-50%);

      @include phone {
        position: static;
        transform: none;
      }
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-self: center;
    width: 200px;
    margin-right: 50px;

    @include tablet {
      margin-top: 30px;
    }

    .price {
      font-size: 0.9rem;
      line-height: 1.25rem;
      font-weight: 600;
      margin-top: 5px;
      color: $black;
    }

    h3 {
      margin: 0 0 10px 0;
      font-size: 0.9rem;
      line-height: 1;
      font-weight: 900;
      text-transform: capitalize;
    }

    .info {
      color: $middle-grey;
      display: flex;
      flex-direction: column;
    }

    p,
    a,
    .type,
    .info {
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
    }

    p {
      margin: 10px 0;
      color: $middle-grey;
    }

    a {
      text-decoration: none;
      margin: 2px 0 0 20px;
      transition: $transition;
      color: $primary-blue;

      &:hover {
        opacity: 0.8;
      }
    }

    .editButton {
      margin-left: 17px;

      button {
        font-size: 0.75rem;
        font-weight: 600;
        line-height: 0.9375rem;
      }
    }

    .type {
      display: flex;
      align-items: center;
      color: $middle-grey;
      margin-top: 10px;

      svg {
        color: $lime;
        font-size: 0.6rem;
        margin-right: 5px;
      }
    }
  }

  .quantity {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-right: 0px;
    font-weight: 600;
    width: 40px;
    justify-content: center;
  }

  .deleteIcon {
    width: 2em;
    height: 2em;
    align-self: center;
    margin-left: 30px;
    flex-shrink: 0;

    @include tablet {
      margin-left: 0;
    }

    @include phone {
      margin-left: 0;
      margin-top: 30px;
    }

    &:hover {
      color: $primary-blue;
    }

    svg {
      width: 2em;
      height: 2em;
    }
  }
}
