@import 'app/theme/mixins';

.day {
  width: 40px;
  height: 40px;
  display: flex;

  border: solid 1px $light-grey;
  background-color: $white-grey;
  border-radius: 5px;

  justify-content: center;
  align-items: center;

  position: relative;

  &.disabled:not(.recurring),
  &.outsideRange {
    border: none;
    background-color: transparent;
  }

  &:not(.selected):not(.disabled):hover {
    background: $white;
    border-color: $primary-blue;
  }

  &.selected:not(.outsideRange) {
    border-radius: 50%;
    color: $white;
    background: $primary-dark-blue;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }

  &:focus-within {
    color: $white !important;
    background: $primary-dark-blue !important;
  }

  &:not(.selected):not(.disabled) {
    .preferredDayBadge {
      display: block;
      position: absolute;
      bottom: -1px;
      right: -1px;
      width: 13px;
      height: 13px;
      background: #8ece56;
      border: solid 1px #6ba844;
      border-top-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &:not(.selected) {
    .recurringDeliveryBadge {
      display: block;
      position: absolute;
      top: 1px;
      left: 1px;
      width: 8px;
      height: 8px;
      background: $primary-blue;
      border: solid 1px darken($primary-blue, 5%);
      border-radius: 50%;
    }
  }
}
