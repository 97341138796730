@import 'app/theme/mixins';
.costcoBtn {
  button {
    background-color: $black !important;
    opacity: 0.75;
    width: 115px;
  }
  button:hover,
  button:active,
  button:focus {
    opacity: 1;
  }
}
