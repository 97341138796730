// colors - primary
$glacier: #00d6f9;
$ice: #9ce6f7;
$lake: #00053e;
$mist: #e7f6fd;
$pond: #022e59;
$river: #0054a6;
$snow: white;
$stream: #00a8e4;

// colors - secondary
$cardinal: #ea0029;
$marigold: #fec553;
$dimmed-marigold: #fff1d4;
$river-rock: black;
$spruce: #00736f;
$stone: #d6d1c4;
$willow: #9ecf7c;

// todo - remove from codebase
$v2-primary-snow: $snow;
$v2-primary-mist: $mist;
$v2-primary-ice: $ice;
$v2-primary-glacier: $glacier;
$v2-primary-stream: $stream;
$v2-primary-river: $river;
$v2-primary-pond: $pond;
$v2-primary-lake: $lake;

$v2-secondary-stone: $stone;
$v2-secondary-marigold: $marigold;
$v2-secondary-cardinal: $cardinal;
$v2-secondary-willow: $willow;
$v2-secondary-spruce: $spruce;
$v2-secondary-river-rock: $river-rock;

$v2-primary-blue: $river;
$v2-hover-color: #f2fbff;

// other / old colors
$black: black;
$blue: #0072ce;
$dark-blue: #0d2743;
$dark-ocean-blue: #00467f;
$deep-blue: #39526c;
$middle-blue: #00364d;
$ocean-blue: #007dbc;
$sky-blue: #d3eafc;
$green: #6cc24a;
$green-dark: #379922;
$light-green: #bff0e7;
$lime: $mist;
$lime-dark: #516a2e;
$grey: #4e4e4e;
$dark-grey: #8e8e8e;
$deep-grey: #555;
$dim-grey: #494949;
$extra-light-grey: #e6e8ed;
$light-grey: #6c6c6c;
$middle-grey: #898d8d;
$middle-dark-grey: #707070;
$sky-grey: #8fc3ea;
$white-grey: #f4f5fa;
$heavy-some: #c7c8ca;
$milk: #f0f1f1;
$red: #de383b;
$light-red: #f80303;
$light-violet: #cbd1e8;
$white: white;
$white-smoke: #f6f6f6;

// generic colors
$primary-blue: $river;
$primary-dark-blue: #036dc2;
$primary-green: #00c993;

// dom-specific colors
$hover-color: $primary-dark-blue;
$hover-border: $primary-dark-blue;
$inactive-blue: #0074a6;
$placeholder-grey: #8a8a8a;
$table-background-grey: #f0f1f8;
$light-background-grey: #f2f3f9;
$disabled-grey: #8a8a8a;
$light-green-arrow: #cbe7a4;

$blue-border: 4px solid rgba(0, 156, 222, 0.2);
$grey-border: 4px solid #c7c8ca;
$thin-grey-border: 1px solid #c7c8ca;
$thin-light-grey-border: 1px solid #e8e8e8;
$transparent-border: 4px solid rgba(255, 255, 255, 0.2);

$drawer-background-blue: rgba(0, 48, 135, 0.8);

$primary-gradient: linear-gradient(270deg, #009ef8 0%, #00c993 100%);
$dark-blue-gradient: linear-gradient(to top, #005b96 0%, #009cde 100%);
$light-grey-gradient: linear-gradient(to top, #e2e2e2 0%, #fff 100%);
$grey-gradient: linear-gradient(to top, #86858c 0%, #e2e2e2 100%);
$dark-grey-gradient: linear-gradient(to top, #373539 0%, #86858c 100%);

$dark-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

// fonts
$nunito: 'nunito-sans', sans-serif;
$sans-pro: 'source-sans-pro', sans-serif;
$benton: 'benton-sans', sans-serif;

$transition: all 0.2s linear;
$smooth-transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

$screen-xs: 320px;
$screen-ss: 375px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1024px;
$screen-xl: 1440px;
