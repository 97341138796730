@import 'app/theme/mixins';
.staticContent {
  align-items: normal !important;

  a {
    color: $primary-dark-blue !important;
    text-decoration: underline !important;
  }
}

.staticSection {
  width: 100%;
}
