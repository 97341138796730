// v2 colors
$v2-primary-blue: #0054a6;

$v2-primary-snow: #fff;
$v2-primary-mist: #e7f6fd;
$v2-primary-ice: #9ce6f7;
$v2-primary-glacier: #00d6f9;
$v2-primary-stream: #00a8e4;
$v2-primary-river: #0054a6;
$v2-primary-pond: #022e59;
$v2-primary-lake: #00053e;

$v2-secondary-stone: #d6d1c4;
$v2-secondary-marigold: #fec553;
$v2-secondary-cardinal: #ea0029;
$v2-secondary-willow: #9ecf7c;
$v2-secondary-spruce: #00736f;
$v2-secondary-river-rock: #000;

// legacy colors

$primary-blue: $v2-primary-blue;
$primary-dark-blue: #036dc2;
$hover-color: $primary-dark-blue;
$hover-border: $primary-dark-blue;
$blue: #0072ce;
$middle-blue: #00364d;
$inactive-blue: #0074a6;
$ocean-blue: #007dbc;
$dark-ocean-blue: #00467f;
$primary-green: #00c993;
$green: #6cc24a;
$green-dark: #379922;
$lime: $v2-primary-mist;
$lime-dark: #516a2e;
$black: #000;
$white: #fff;
$white-smoke: #f6f6f6;
$dark-blue: #0d2743;
$extra-light-grey: #e6e8ed;
$light-grey: #6c6c6c;
$light-button-grey: #b9b9b9;
$middle-grey: #898d8d;
$middle-dark-grey: #707070;
$white-grey: #f4f5fa;
$milk: #f0f1f1;
$heavy-some: #c7c8ca;
$deep-grey: #555555;
$grey: #4e4e4e;
$dark-grey: #8e8e8e;
$deep-blue: #39526c;
$sky-blue: #d3eafc;
$light-green: #bff0e7;
$red: #de383b;
$light-red: #f80303;
$dim-grey: #494949;
$sky-grey: #8fc3ea;
$light-violet: #cbd1e8;
$medium-red: #de0e0e;
$placeholder-grey: #8a8a8a;
$table-background-grey: #f0f1f8;
$light-background-grey: #f2f3f9;
$disabled-grey: #8a8a8a;

$screen-xs-min: 320px;
$screen-xs-max: 576px;
$screen-sm-min: 768px;
$screen-md-min: 1024px;
$screen-lg-min: 1024px;

$blue-border: 4px solid rgba(0, 156, 222, 0.2);
$grey-border: 4px solid #c7c8ca;
$thin-grey-border: 1px solid #c7c8ca;
$transparent-border: 4px solid rgba(255, 255, 255, 0.2);

$outline: 2px $black dashed;

$drawer-background-blue: rgba(0, 48, 135, 0.8);

$primary-gradient: linear-gradient(270deg, #009ef8 0%, #00c993 100%);
$dark-blue-gradient: linear-gradient(to top, #005b96 0%, #009cde 100%);
$light-grey-gradient: linear-gradient(to top, #e2e2e2 0%, #ffffff 100%);
$grey-gradient: linear-gradient(to top, #86858c 0%, #e2e2e2 100%);
$dark-grey-gradient: linear-gradient(to top, #373539 0%, #86858c 100%);

$dark-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

$sans-pro: 'source-sans-pro', sans-serif;
$benton: 'benton-sans', sans-serif;

$transition: all 0.2s linear;
$smooth-transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

:export {
  hoverColor: $hover-color;
  hoverBorder: $hover-border;
  primaryBlue: $primary-blue;
  primaryDarkBlue: $primary-dark-blue;
  middleBlue: $middle-blue;
  blue: $blue;
  inactiveBlue: $inactive-blue;
  oceanBlue: $ocean-blue;
  darkOceanBlue: $dark-ocean-blue;
  primaryGreen: $primary-green;
  black: $black;
  white: $white;
  whiteSmoke: $white-smoke;
  whiteGrey: $white-grey;
  darkBlue: $dark-blue;
  heavySome: $heavy-some;
  grey: $grey;
  lightGrey: $light-grey;
  extraLightGrey: $extra-light-grey;
  darkGrey: $dark-grey;
  middle-grey: $middle-grey;
  middle-dark-grey: $middle-dark-grey;
  deep-grey: $deep-grey;
  deepBlue: $deep-blue;
  skyBlue: $sky-blue;
  lightGreen: $light-green;
  red: $red;
  lightRed: $light-red;
  dimGrey: $dim-grey;
  skyGrey: $sky-grey;
  middleGrey: $middle-grey;
  lime: $lime;
  limeDark: $lime-dark;
  lightViolet: $light-violet;
  lightButtonGrey: $light-button-grey;
  mediumRed: $medium-red;
  placeholderGrey: $placeholder-grey;
  primaryIce: $v2-primary-ice;

  darkShadow: $dark-shadow;

  drawerBackgroundBlue: $drawer-background-blue;

  breakpointsXs: $screen-xs-min;
  breakpointsSm: $screen-sm-min;
  breakpointsMd: $screen-md-min;
  breakpointsLg: $screen-lg-min;

  blueBorder: $blue-border;
  greyBorder: $grey-border;
  thinGreyBorder: $thin-grey-border;
  transparentBorder: $transparent-border;

  primaryGradient: $primary-gradient;
  darkBlueGradient: $dark-blue-gradient;
  lightGreyGradient: $light-grey-gradient;
  greyGradient: $grey-gradient;
  darkGreyGradient: $dark-grey-gradient;

  fontSansPro: $sans-pro;
  fontBenton: $benton;

  transitionAll: $transition;
  smoothTransition: $smooth-transition;

  stream: $v2-primary-stream;
  pond: $v2-primary-pond;
  lake: $v2-primary-lake;
  river: $v2-primary-blue;
  ice: $v2-primary-ice;
}